import {
  RESET_PASSWORD_NEW_PASSWORD,
  RESET_PASSWORD_NEW_PASSWORD_CHECK,
  RESET_PASSWORD_PASSWORD_INVALID,
  RESET_PASSWORD_PASSWORD_IS_REQUIRED,
  RESET_PASSWORD_PASSWORDS_DO_NOT_MATCH,
} from '@asaprint/asap/locales/client.js';
import TextField from '@engined/client/components/forms/fields/TextField.js';
import { LocaleContextValue, useLocale } from '@engined/client/contexts/LocaleContext.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import isStrongPassword from '@engined/core/validators/isStrongPassword.js';
import React from 'react';

export interface FormValues {
  password: string;
  passwordConfirmation: string;
}

export const initialValues: FormValues = {
  password: '',
  passwordConfirmation: '',
};

export function validate(values: FormValues, t: LocaleContextValue['t']) {
  const errors: FormErrors<FormValues> = {};
  if (!values.password) {
    errors.password = t(RESET_PASSWORD_PASSWORD_IS_REQUIRED);
  } else if (!isStrongPassword(values.password)) {
    errors.password = t(RESET_PASSWORD_PASSWORD_INVALID);
  }

  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = t(RESET_PASSWORD_PASSWORDS_DO_NOT_MATCH);
  }

  return errors;
}

interface Props {
  className?: string;
}

const ResetPasswordForm: React.FunctionComponent<Props> = ({ className }) => {
  const { t } = useLocale();

  return (
    <div className={className}>
      <TextField
        margin="normal"
        variant="standard"
        required
        fullWidth
        name="password"
        label={t(RESET_PASSWORD_NEW_PASSWORD)}
        type="password"
        autoComplete="new-password"
        autoFocus
      />
      <TextField
        margin="normal"
        variant="standard"
        required
        fullWidth
        name="passwordConfirmation"
        label={t(RESET_PASSWORD_NEW_PASSWORD_CHECK)}
        type="password"
        autoComplete="new-password"
      />
    </div>
  );
};

ResetPasswordForm.displayName = 'ResetPasswordForm';

export default React.memo<Props>(ResetPasswordForm);
