import { useMutation } from '@apollo/client/index.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import ResetPasswordForm, {
  FormValues,
  initialValues,
  validate as formValidate,
} from '@asaprint/asap/components/forms/ResetPasswordForm.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import {
  RESET_PASSWORD_BACK_TO_SIGN_IN,
  RESET_PASSWORD_CHANGED_MESSAGE,
  RESET_PASSWORD_DESCRIPTION,
  RESET_PASSWORD_SUBMIT,
  RESET_PASSWORD_TOKEN_INVALID,
} from '@asaprint/asap/locales/client.js';
import { AUTH_LOGIN_ROUTE, DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import {
  ResetPassword_ChangePassword,
  ResetPassword_Load,
} from '@asaprint/asap/routes/auth/reset-password.$token.graphql';
import {
  ResetPassword_ChangePasswordMutation,
  ResetPassword_ChangePasswordMutationVariables,
  ResetPassword_LoadQuery,
  ResetPassword_LoadQueryVariables,
} from '@asaprint/asap/schema.client.types.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import Loading from '@engined/client/components/Loading.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { LoaderFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Alert, Box, Button, LinearProgress, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink, redirect, useLocation, useNavigate, useParams } from 'react-router-dom';

const logger = getLogger('@intranet/routes/auth/reset-password.$token');

interface Props {}

const ResetPasswordPage: React.FunctionComponent<Props> = () => {
  const { t } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useParams();
  const { loading, data, error } = useQuery<ResetPassword_LoadQuery, ResetPassword_LoadQueryVariables>(
    ResetPassword_Load,
    {
      variables: { token: token },
    },
  );
  const [changePasswordExecute] = useMutation<
    ResetPassword_ChangePasswordMutation,
    ResetPassword_ChangePasswordMutationVariables
  >(ResetPassword_ChangePassword, {
    refetchQueries: ['App_Load'],
  });
  const { login } = useAuth();

  const onSubmit: OnSubmit<FormValues> = async ({ password, passwordConfirmation }) => {
    try {
      const response = await changePasswordExecute({ variables: { token, password } });
      login(response.data.resetPassword);

      enqueueSnackbar(t(RESET_PASSWORD_CHANGED_MESSAGE), {
        variant: 'success',
      });

      navigate(DASHBOARD_ROUTE);
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  if (error || (loading && !data)) {
    return <Loading error={error} />;
  }

  if (!data.resetToken.valid) {
    return (
      <div>
        <Alert color="error">{t(RESET_PASSWORD_TOKEN_INVALID)}</Alert>
        <Button
          sx={{ my: 1 }}
          component={RouterLink}
          to={AUTH_LOGIN_ROUTE}
          fullWidth
          variant="contained"
          color="primary"
        >
          {t(RESET_PASSWORD_BACK_TO_SIGN_IN)}
        </Button>
      </div>
    );
  }

  return (
    <>
      <p>{t(RESET_PASSWORD_DESCRIPTION)}</p>
      <Form defaultValues={initialValues} onSubmit={onSubmit} validate={formValidate}>
        {({ formState: { isSubmitting } }) => (
          <Box sx={{ mt: 1 }}>
            <Box sx={{ display: 'none' }}>
              <input type="text" name="username" value={data.resetToken.username} autoComplete="username" readOnly />
            </Box>
            <ResetPasswordForm />
            <Box sx={{ mt: 3, mb: 2 }}>
              {isSubmitting && <LinearProgress sx={{ my: 1 }} />}
              <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                {t(RESET_PASSWORD_SUBMIT)}
              </Button>
            </Box>
            <Box sx={{ mt: 1, textAlign: 'center' }}>
              <Link variant="body2" component={RouterLink} to={AUTH_LOGIN_ROUTE}>
                {t(RESET_PASSWORD_BACK_TO_SIGN_IN)}
              </Link>
            </Box>
          </Box>
        )}
      </Form>
    </>
  );
};

ResetPasswordPage.displayName = 'ResetPasswordPage';

export default React.memo<Props>(ResetPasswordPage);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs<'token'>) => {
  if (ENV.SERVER) {
    if (req.user) {
      return redirect((req.query.next as string) ?? DASHBOARD_ROUTE);
    }
  }
  return null;
};
